import * as Sentry from '@sentry/vue'
import { consola } from 'consola'
import { useRuntimeConfig, defineNuxtPlugin, useRouter } from '#imports'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const { sentry }: {
    sentry: {
      dsn: string
      environment: string
    }
  } = useRuntimeConfig().public
  consola.info(`[plugin.sentry.client] ${JSON.stringify(sentry)}`)

  if (!sentry.dsn) {
    console.warn('Sentry DSN not set, skipping Sentry initialization')

    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    // Configure this whole part as you need it!

    tracesSampleRate: 0.2, // Change in prod

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', 'https://your-server.com'],
    tracePropagationTargets: ['localhost'],

    replaysSessionSampleRate: 1.0, // Change in prod
    replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
  })
})
