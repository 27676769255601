import type { UseFetchOptions } from "nuxt/app";
import { defu } from "defu";

export function useCustomFetch<T>(
  url: string,
  options: UseFetchOptions<T> = {}
) {

  const proAdminCookie = useCookie('fo-pro-admin')
  const headerProAdmin = useRequestHeader('fo-pro-admin')
  // console.log('headerProAdmin:', headerProAdmin);
  // console.log('proAdminCookie:', proAdminCookie.value);

  // const userAuth = useCookie("access_token");
  const accessToken = useAuth().data.value?.accessToken

  const config = useRuntimeConfig();
  // console.log(`token fetch: ${userAuth.value}`);

  const defaults: UseFetchOptions<T> = {
    baseURL: config.public.baseUrl,
    // cache request
    key: url,

    // set user token if connected

    headers: accessToken
      ? { Authorization: `Bearer ${accessToken}`, 'fo-pro-admin': JSON.stringify(proAdminCookie.value) }
      : { 'fo-pro-admin': JSON.stringify(proAdminCookie.value) },

    onResponse(_ctx) {
      // _ctx.response._data = new myBusinessResponse(_ctx.response._data)
    },

    onResponseError(_ctx) {
      // throw new myBusinessError()
    },
  };

  // cl("useCustomFetch", `defaults: ${JSON.stringify(defaults)}`);

  // for nice deep defaults, please use unjs/defu
  const params = defu(options, defaults);

  return useFetch(url, params);
}
