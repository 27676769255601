import revive_payload_client_4sVQNw7RlN from "/var/www/ruk/fo-front-nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/ruk/fo-front-nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/ruk/fo-front-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/ruk/fo-front-nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/ruk/fo-front-nuxt/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/ruk/fo-front-nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/ruk/fo-front-nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/var/www/ruk/fo-front-nuxt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/ruk/fo-front-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/ruk/fo-front-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_t2GMTTFnMT from "/var/www/ruk/fo-front-nuxt/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_AOuQ1DYzjk from "/var/www/ruk/fo-front-nuxt/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import sentry_client_mtxY2L85nr from "/var/www/ruk/fo-front-nuxt/sentry/plugins/sentry.client.ts";
import api_GFfDXud5Cr from "/var/www/ruk/fo-front-nuxt/plugins/api.ts";
import imask_directive_QbiHGhrIao from "/var/www/ruk/fo-front-nuxt/plugins/imask-directive.ts";
import maska_directive_QXriEqi7SZ from "/var/www/ruk/fo-front-nuxt/plugins/maska-directive.ts";
import vue_notification_client_3tTAjVhyyr from "/var/www/ruk/fo-front-nuxt/plugins/vue-notification.client.ts";
import vuedraggable_client_1wiR6rReiY from "/var/www/ruk/fo-front-nuxt/plugins/vuedraggable.client.ts";
import vuetify_7h9QAQEssH from "/var/www/ruk/fo-front-nuxt/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_t2GMTTFnMT,
  plugin_AOuQ1DYzjk,
  sentry_client_mtxY2L85nr,
  api_GFfDXud5Cr,
  imask_directive_QbiHGhrIao,
  maska_directive_QXriEqi7SZ,
  vue_notification_client_3tTAjVhyyr,
  vuedraggable_client_1wiR6rReiY,
  vuetify_7h9QAQEssH
]