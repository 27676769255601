import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import { md2 } from "vuetify/blueprints";
import * as labs from "vuetify/labs/components";

import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

const { customDarkTheme, customLightTheme } = useVuetifyThemes();
const { defaults } = useVuetifyThemeDefaults();
// import { defaults as vuetifyDefaults } from "vuetify";
import { ru } from "vuetify/locale";

const vuetify = createVuetify({
  ssr: true,
  blueprint: md2,
  defaults,
  // defaults: vuetifyDefaults,

  theme: {
    defaultTheme: "customLightTheme",
    themes: {
      customDarkTheme,
      customLightTheme,
    },
    // variations: {
    //   colors: ['primary', 'secondary'],
    //   lighten: 3,
    //   darken: 3,
    // },
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
  components: {
    ...labs,
  },

  // locale: {
  //   locale: "ru",
  //   fallback: "en",
  // },
});

export default defineNuxtPlugin((app) => {
  // console.log(`[plugins.vuetify] init`);

  app.vueApp.use(vuetify);
});
