import * as vt from "vue-toastification";
import "vue-toastification/dist/index.css";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(vt.default, {
    position: vt.POSITION.BOTTOM_RIGHT,
    transition: "Vue-Toastification__fade",
    timeout: 5000,
  });
  return {
    provide: {
      toast: vt.useToast(),
    },
  };
});
