export default defineNuxtPlugin({
  setup() {
    const { baseUrl } = useRuntimeConfig().public

    const proAdminCookie = useCookie('fo-pro-admin')
    const accessToken = useAuth().data.value?.accessToken

    const api = $fetch.create({
      baseURL: baseUrl,

      onRequest({ request, options, error }) {
        if (accessToken) {
          // Add Authorization header
          options.headers = options.headers || {}
          options.headers.Authorization = `Bearer ${accessToken}`
          options.headers['fo-pro-admin'] = JSON.stringify(proAdminCookie.value)
        }
      },
      // onResponse({ response }) {
      // // response._data = new myBusinessResponse(response._data)
      // },
      // onResponseError({ response }) {
      //   if (response.status === 401)
      //     return navigateTo('/login')
      // },
    })
    return {
      provide: { api },
    }
  },
})
