<script setup lang="ts">
import { usePermissionsStore } from './stores/db/permissions';
import { useProjectsStore } from './stores/db/projects';
import { useUIStore } from '@/stores/ui';


// title
const config = useRuntimeConfig();
useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk
      ? `${titleChunk} - ${config.public.siteTitle}`
      : `${config.public.siteTitle}`;
  },
});
const proAdminCookie = useCookie('fo-pro-admin')
const { clientIp } = storeToRefs(useUIStore())

const { findAll: loadPermissions } = usePermissionsStore();
const loggedIn = computed(() => useAuth().data.value?.accessToken ? true : false)
if (loggedIn)
  await loadPermissions();


// pro-admin-api
if (import.meta.env.MODE !== 'development') {
  const { proAdminUrl } = useRuntimeConfig().public
  try {
    const data = await $fetch(`${proAdminUrl}`)
    proAdminCookie.value = JSON.stringify(data)
  } catch (e) {
    proAdminCookie.value = null
  }
  console.log('[app] pro-admin-api: ', proAdminCookie.value);
}
if (process.client) {
  try {
    clientIp.value = await $fetch('https://api.ipify.org/')
  }
  catch (e) {
    clientIp.value = ''
  }
  cl('app', `ip: ${clientIp.value}`)
}
// console.log('app.env: ', import.meta.env.MODE);
// cs('app', `loggedIn: ${loggedIn.value}`)

// platform
//const clientOS = useCookie('fo-os')



</script>
<template>
  <div>
    <NuxtLayout>
      <NuxtLoadingIndicator />
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<style>
.v-navigation-drawer--right {
  z-index: 999999 !important;
}

.dark_link:visited {
  color: #31363b !important;
}
</style>
