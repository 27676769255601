import { defineStore } from "pinia";
import { type IPermission } from "~/types";

export const usePermissionsStore = defineStore("permissions", () => {
  const loading = ref<boolean>(false);
  const permissions = ref<IPermission[]>([]);

  const findAll = async () => {
    // if (permissions.value) {
    //   console.log(
    //     `[${side()}][stores.db.permissions.findAll] check`,
    //     permissions.value
    //   );
    //   return;
    // }
    loading.value = true;
    const { data, error } = await useCustomFetch<IPermission[]>(
      "/permissions/access",
      {}
    );

    if (error.value) {
      console.error(
        `[${side()}][stores.db.permissions.findAll] error: `,
        error.value.message
      );
    } else {
      permissions.value = data.value ? data.value : [];
    }
    loading.value = false;
  };

  return {
    loading,
    permissions,

    findAll,
  };
});
