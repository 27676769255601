import { ThemeDefinition } from "vuetify";

export const useVuetifyThemes = () => {
  // Light mode theme
  const customLightTheme: ThemeDefinition = {
    dark: false,
    colors: {
      // background: "#ffffff",
      // surface: "#fff",
      // primary: "#0096c7",
      // secondary: "#a0b9c8",
      // accent: "#048ba8",
      // error: "#ef476f",
      // info: "#2196F3",
      // success: "#06d6a0",
      // warning: "#ffd166",
      primary: "#1976D2",
      accent: "#37474F",
      secondary: "#FF8F00",
      info: "#26A69A",
      warning: "#FFC107",
      error: "#DD2C00",
      success: "#00E676",
    },
  };
  // Dark mode theme
  const customDarkTheme: ThemeDefinition = {
    dark: true,
    colors: {
      primary: "#0096c7",
      secondary: "#2B2B49",
      "secondary-darken-1": "#1d1f33",
      "secondary-lighten-1": "#303052",
      surface: "#282843",
      title: "#C3C1D5",
      content: "#8381a7",
      accent: "#6386e1",
      error: "#e47171",
      info: "#24a6c5",
      background: "#1d1f33",
    },
  };

  // return { customDarkTheme, customLightTheme, swithTheme };
  return { customDarkTheme, customLightTheme };
};
