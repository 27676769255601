<script setup lang="ts">
// const props = defineProps({ error: Object })
const error = useError();
const { statusCode, message, url } = error;
const router = useRouter();
console.log(router.currentRoute.value.fullPath);

// const handleError = () => clearError({ redirect: url });
const handleError = () => clearError({ redirect: "/" });
const more = ref<boolean>(false);
</script>
<template>
  <div>
    <v-card max-width="900"
            class="mx-auto mt-16">
      <v-card-title class="text-center text-h3">
        {{ error?.statusCode }}
      </v-card-title>
      <v-card-subtitle> </v-card-subtitle>
      <v-card-text>
        {{ error?.message }}
        <v-btn variant="text"
               class="ml-2"
               @click="more = !more">{{ more ? "less" : "more" }}</v-btn>
      </v-card-text>
      <v-card-text>
        <div v-if="more"
             v-html="error?.stack"></div>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-spacer></v-spacer>
        <v-btn @click="handleError"
               color="primary">Go Back</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<style scoped></style>
