import { usePermissionsStore } from "~/stores/db/permissions";
import type { IPermission } from "~/types";
import { dashboardList } from "~/types/ui";

interface IDashboardItem {
  title: string;
  value: string;
  to: string;
  icon: string;
}

const comparePermission = (
  accessRoles: string[] | undefined,
  userRoles: string[] | undefined
): boolean => {
  let result = false;
  if (userRoles) {
    for (let i = 0; i < userRoles?.length; i++) {
      if (accessRoles?.includes(userRoles![i])) {
        result = true;
      }
    }
  }
  return result;
};

export default defineNuxtRouteMiddleware(async (to, from) => {
  // skip middleware on server
  if (import.meta.server) return;



  // left form menu -->
  // const { leftMenu } = storeToRefs(useUIStore());
  // const { hideMenu } = useLeftFormMenu()
  // if (to.path === '/tasks') {
  //   leftMenu.value = false;
  // }
  // if (from.path === '/tasks') {
  //   leftMenu.value = true;
  // }


  // left form menu <--

  const { data: sessionData } = useAuth();
  const { permissions } = storeToRefs(usePermissionsStore());
  const userRoles = sessionData.value?.roles;

  // cl("middleware.iu to: ", `${JSON.stringify(to.name)}`);

  const dashboardItem: IDashboardItem | undefined = dashboardList.find(
    (item) => item.to === to.path
  );
  if (dashboardItem) {
    // cl("middleware.dashboardItem", `${JSON.stringify(dashboardItem)}`);
    const accessRoles = permissions.value!.find(
      (item: IPermission) => item.subject === dashboardItem.value
    )?.roles;

    if (!comparePermission(accessRoles, userRoles)) {
      // ce("middleware.iu", `WTF`);
      return abortNavigation(`У вас нет прав для просмотра этой страницы.`);
    }
  }
});
