import validate from "/var/www/ruk/fo-front-nuxt/node_modules/nuxt/dist/pages/runtime/validate.js";
import alert_45global from "/var/www/ruk/fo-front-nuxt/middleware/alert.global.ts";
import iu_45global from "/var/www/ruk/fo-front-nuxt/middleware/iu.global.ts";
import manifest_45route_45rule from "/var/www/ruk/fo-front-nuxt/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  alert_45global,
  iu_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/var/www/ruk/fo-front-nuxt/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}