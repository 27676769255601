import { DefaultsInstance } from "vuetify/lib/framework.mjs";

export const useVuetifyThemeDefaults = () => {
  const defaults: DefaultsInstance = {
    // VAppBar: {
    //   elevation: 0,
    // },
    // VBtn: {
    //   variant: "flat",
    //   height: 38,
    //   rounded: "lg",
    //   size: "small",
    // },
    // VTextField: {
    //   color: "primary",
    //   variant: "outlined",
    //   density: "comfortable",
    // },
    VDataTable: {
      itemsPerPage: 100,
    },
  };
  return { defaults };
};
