import { defineStore } from 'pinia'

type AlertType = 'success' | 'info' | 'warning' | 'error';
type AlertVariantStyle =
  | 'text'
  | 'flat'
  | 'elevated'
  | 'tonal'
  | 'outlined'
  | 'plain';

interface IAlert {
  message: string;
  title: string;
  type: AlertType;
  variant: AlertVariantStyle;
}

export const useAlertStore = defineStore('alert', () => {
  // const theme = useTheme();
  // const isDark = ref(theme.global.current.value.dark);

  // TODO: change alert variant by vuetify theme dark or light
  // const currentTheme = useLocalStorage(STORAGE_CURRENT_THEME, '')
  // const variant = ref<AlertVariantStyle>(
  //   currentTheme.value === 'customDarkTheme' ? 'tonal' : 'flat'
  // )

  const variant = ref<AlertVariantStyle>('tonal')
  // console.log(`[stores.alert] theme: `, currentTheme.value, variant);

  const alert = ref<IAlert | null>(null)

  const success = (message: string, title = '') => {
    // console.log(currentTheme.value, variant.value);
    alert.value = {
      message,
      title,
      type: 'success',
      variant: variant.value
    }
  }
  const info = (message: string, title = '') =>
  (alert.value = {
    message,
    title,
    type: 'info',
    variant: variant.value
  })
  const warning = (message: string, title = '') =>
  (alert.value = {
    message,
    title,
    type: 'warning',
    variant: variant.value
  })
  const error = (message: string, title = '') =>
  (alert.value = {
    message,
    title,
    type: 'error',
    variant: variant.value
  })

  const clear = () => (alert.value = null)

  return { alert, success, info, warning, error, clear }
})
