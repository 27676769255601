import { consola } from 'consola'

export const cl = (origin: string, msg: string = "") => {
  if (msg) console.log(`[${origin}] ${msg}`);
  else console.log(`[${origin}]`);
};
export const ci = (origin: string, msg: string = "") => {
  if (msg) consola.info(`[${origin}] ${msg}`);
  else consola.info(`[${origin}]`);
};
export const cs = (origin: string, msg: string = "") => {
  if (msg) consola.success(`[${origin}] ${msg}`);
  else consola.success(`[${origin}]`);
};
export const ce = (origin: string, msg: string) => {
  if (msg) consola.error(`[${origin}] ${msg}`);
  else consola.error(`[${origin}]`);
};

export function randomString(length: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
