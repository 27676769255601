import { default as loginKVr6CuM5OtMeta } from "/var/www/ruk/fo-front-nuxt/pages/auth/login.vue?macro=true";
import { default as barRPOFYFxrrRMeta } from "/var/www/ruk/fo-front-nuxt/pages/bar.vue?macro=true";
import { default as _91id_93yhbBMSXZtKMeta } from "/var/www/ruk/fo-front-nuxt/pages/dashboard/custom-form-fields/[id].vue?macro=true";
import { default as custom_45form_45fieldsGXNlntfOnfMeta } from "/var/www/ruk/fo-front-nuxt/pages/dashboard/custom-form-fields.vue?macro=true";
import { default as _91id_93xXFjHepCmWMeta } from "/var/www/ruk/fo-front-nuxt/pages/dashboard/directories/[id].vue?macro=true";
import { default as directories5i3VxcmSaRMeta } from "/var/www/ruk/fo-front-nuxt/pages/dashboard/directories.vue?macro=true";
import { default as form_45elements_45parentsfmNlaA8FdOMeta } from "/var/www/ruk/fo-front-nuxt/pages/dashboard/form-elements-parents.vue?macro=true";
import { default as _91id_9320CPFP2u1iMeta } from "/var/www/ruk/fo-front-nuxt/pages/dashboard/form-elements/[id].vue?macro=true";
import { default as form_45elements9EmFS6fM2gMeta } from "/var/www/ruk/fo-front-nuxt/pages/dashboard/form-elements.vue?macro=true";
import { default as _91id_93hpMGlQm89nMeta } from "/var/www/ruk/fo-front-nuxt/pages/dashboard/forms/[id].vue?macro=true";
import { default as formsuYTnLWXRM2Meta } from "/var/www/ruk/fo-front-nuxt/pages/dashboard/forms.vue?macro=true";
import { default as global_45optionsDWQo85bZaPMeta } from "/var/www/ruk/fo-front-nuxt/pages/dashboard/global-options.vue?macro=true";
import { default as groupsVNoobbZhm3Meta } from "/var/www/ruk/fo-front-nuxt/pages/dashboard/groups.vue?macro=true";
import { default as guest_45formsSRt6cgypqLMeta } from "/var/www/ruk/fo-front-nuxt/pages/dashboard/guest-forms.vue?macro=true";
import { default as indexOoseX9bDuXMeta } from "/var/www/ruk/fo-front-nuxt/pages/dashboard/index.vue?macro=true";
import { default as optionscpNNZUoIwQMeta } from "/var/www/ruk/fo-front-nuxt/pages/dashboard/options.vue?macro=true";
import { default as _91id_93usiPL4wQ5CMeta } from "/var/www/ruk/fo-front-nuxt/pages/dashboard/permissions/[id].vue?macro=true";
import { default as permissionsY6riOaJzT1Meta } from "/var/www/ruk/fo-front-nuxt/pages/dashboard/permissions.vue?macro=true";
import { default as _91id_93Txc9ecMjyiMeta } from "/var/www/ruk/fo-front-nuxt/pages/dashboard/projects/[id].vue?macro=true";
import { default as projectscqX3o85VWlMeta } from "/var/www/ruk/fo-front-nuxt/pages/dashboard/projects.vue?macro=true";
import { default as rolesokiaqIUJiWMeta } from "/var/www/ruk/fo-front-nuxt/pages/dashboard/roles.vue?macro=true";
import { default as _91id_93nOeVW38nDmMeta } from "/var/www/ruk/fo-front-nuxt/pages/dashboard/users/[id].vue?macro=true";
import { default as indexsQztto3a6KMeta } from "/var/www/ruk/fo-front-nuxt/pages/dashboard/users/index.vue?macro=true";
import { default as _91id_93V4W20vhi1cMeta } from "/var/www/ruk/fo-front-nuxt/pages/dispatch/[id].vue?macro=true";
import { default as donemGkUpUbDI3Meta } from "/var/www/ruk/fo-front-nuxt/pages/dispatch/done.vue?macro=true";
import { default as dispatchzd84Ih2PDGMeta } from "/var/www/ruk/fo-front-nuxt/pages/dispatch.vue?macro=true";
import { default as _91_46_46_46slug_93rb3qehRXFSMeta } from "/var/www/ruk/fo-front-nuxt/pages/docs/[...slug].vue?macro=true";
import { default as faqdFaUsbZmz8Meta } from "/var/www/ruk/fo-front-nuxt/pages/faq.vue?macro=true";
import { default as indexJnCQG4p72IMeta } from "/var/www/ruk/fo-front-nuxt/pages/foo/index.vue?macro=true";
import { default as indexg94shlrfCLMeta } from "/var/www/ruk/fo-front-nuxt/pages/index.vue?macro=true";
import { default as taskso47okpnjWDMeta } from "/var/www/ruk/fo-front-nuxt/pages/tasks.vue?macro=true";
export default [
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginKVr6CuM5OtMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "bar",
    path: "/bar",
    meta: barRPOFYFxrrRMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/bar.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-custom-form-fields",
    path: "/dashboard/custom-form-fields",
    meta: custom_45form_45fieldsGXNlntfOnfMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dashboard/custom-form-fields.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-custom-form-fields-id",
    path: ":id()",
    meta: _91id_93yhbBMSXZtKMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dashboard/custom-form-fields/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-directories",
    path: "/dashboard/directories",
    meta: directories5i3VxcmSaRMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dashboard/directories.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-directories-id",
    path: ":id()",
    meta: _91id_93xXFjHepCmWMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dashboard/directories/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-form-elements-parents",
    path: "/dashboard/form-elements-parents",
    meta: form_45elements_45parentsfmNlaA8FdOMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dashboard/form-elements-parents.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-form-elements",
    path: "/dashboard/form-elements",
    meta: form_45elements9EmFS6fM2gMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dashboard/form-elements.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-form-elements-id",
    path: ":id()",
    meta: _91id_9320CPFP2u1iMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dashboard/form-elements/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-forms",
    path: "/dashboard/forms",
    meta: formsuYTnLWXRM2Meta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dashboard/forms.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-forms-id",
    path: ":id()",
    meta: _91id_93hpMGlQm89nMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dashboard/forms/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-global-options",
    path: "/dashboard/global-options",
    meta: global_45optionsDWQo85bZaPMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dashboard/global-options.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-groups",
    path: "/dashboard/groups",
    meta: groupsVNoobbZhm3Meta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dashboard/groups.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-guest-forms",
    path: "/dashboard/guest-forms",
    meta: guest_45formsSRt6cgypqLMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dashboard/guest-forms.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexOoseX9bDuXMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-options",
    path: "/dashboard/options",
    meta: optionscpNNZUoIwQMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dashboard/options.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-permissions",
    path: "/dashboard/permissions",
    meta: permissionsY6riOaJzT1Meta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dashboard/permissions.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-permissions-id",
    path: ":id()",
    meta: _91id_93usiPL4wQ5CMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dashboard/permissions/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-projects",
    path: "/dashboard/projects",
    meta: projectscqX3o85VWlMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dashboard/projects.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-projects-id",
    path: ":id()",
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dashboard/projects/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-roles",
    path: "/dashboard/roles",
    meta: rolesokiaqIUJiWMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dashboard/roles.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-users-id",
    path: "/dashboard/users/:id()",
    meta: _91id_93nOeVW38nDmMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dashboard/users/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-users",
    path: "/dashboard/users",
    meta: indexsQztto3a6KMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dashboard/users/index.vue").then(m => m.default || m)
  },
  {
    name: "dispatch",
    path: "/dispatch",
    meta: dispatchzd84Ih2PDGMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dispatch.vue").then(m => m.default || m),
    children: [
  {
    name: "dispatch-id",
    path: ":id()",
    meta: _91id_93V4W20vhi1cMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dispatch/[id].vue").then(m => m.default || m)
  },
  {
    name: "dispatch-done",
    path: "done",
    meta: donemGkUpUbDI3Meta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/dispatch/done.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "docs-slug",
    path: "/docs/:slug(.*)*",
    meta: _91_46_46_46slug_93rb3qehRXFSMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/docs/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqdFaUsbZmz8Meta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "foo",
    path: "/foo",
    meta: indexJnCQG4p72IMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/foo/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexg94shlrfCLMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "tasks",
    path: "/tasks",
    meta: taskso47okpnjWDMeta || {},
    component: () => import("/var/www/ruk/fo-front-nuxt/pages/tasks.vue").then(m => m.default || m)
  }
]