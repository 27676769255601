import IMask from 'imask';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive("imask", {
        created(el, binding, vnode: VNode, prevVnode) {

            const maska = binding.value;
            if (!maska) return

            if (el.type === 'text')
                el.__innerInput__ = el
            else
                el.__innerInput__ = el.getElementsByTagName('input')[0]
            // console.log('inner', el.__innerInput__);

            const imask = IMask(el.__innerInput__, maska);
        },
        getSSRProps(binding, vnode) {
            // you can provide SSR-specific props here
            return {}
        }
    },

    )
})