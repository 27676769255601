import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";


export const useUIStore = defineStore("ui", () => {
  const drawer = ref<boolean>(true);
  const rightDrawer = ref<boolean>(false);
  const clientIp = ref('')
  // const leftMenu = ref(true)
  // const leftMenu = useLocalStorage<boolean>('fo-left-form-menu', true)


  // const leftFormMenu = useCookie("fo-left-form-menu", {
  //   maxAge: 60 * 60 * 24 * 31,
  //   default: () => true,
  //   sameSite: "none",
  // });

  const leftMenu = useCookie<boolean>("fo-left-f-menu", {
    maxAge: 60 * 60 * 24 * 31,
    // default: () => true,
  });

  // if (!leftMenu.value) leftMenu.value = true

  const isAppDrawerVisible = ref(true); // не показывать drawer

  const toggleDrawer = () => {
    drawer.value = !drawer.value;
  };
  const toggleRightDrawer = () => {
    rightDrawer.value = !rightDrawer.value;
  };

  return {
    drawer,
    isAppDrawerVisible,
    toggleDrawer,

    rightDrawer,
    toggleRightDrawer,

    leftMenu,
    clientIp
  };
});
