export const dashboardList = [
  // { title: 'Dashboard', value: '', to: '/dashboard', icon: 'mdi-view-dashboard' },
  {
    title: "Проекты",
    value: "Projects",
    to: "/dashboard/projects",
    icon: "mdi-folder",
  },
  {
    title: "Формы",
    value: "Forms",
    to: "/dashboard/forms",
    icon: "mdi-list-box",
  },
  {
    title: "Категории элементов форм",
    value: "FormElementsParents",
    to: "/dashboard/form-elements-parents",
    icon: "mdi-format-list-group",
  },
  {
    title: "Настраиваемые поля",
    value: "CustomFormFields",
    to: "/dashboard/custom-form-fields",
    icon: "mdi-backburger",
  },
  {
    title: "Элементы форм",
    value: "FormElements",
    to: "/dashboard/form-elements",
    icon: "mdi-list-status",
  },
  {
    title: "Пользователи",
    value: "Users",
    to: "/dashboard/users",
    icon: "mdi-account",
  },
  {
    title: "Группы",
    value: "Groups",
    to: "/dashboard/groups",
    icon: "mdi-account-group",
  },
  {
    title: "Разрешения",
    value: "Permissions",
    to: "/dashboard/permissions",
    icon: "mdi-key",
  },
  {
    title: "Справочники",
    value: "Directories",
    to: "/dashboard/directories",
    icon: "mdi-file-table-box-multiple",
  },
  {
    title: "Формы для гостей",
    value: "GuestForms",
    to: "/dashboard/guest-forms",
    icon: " mdi-table-lock",
  },

  {
    title: "Настройки",
    value: "Options",
    to: "/dashboard/global-options",
    icon: "mdi-cog",
  },
];
